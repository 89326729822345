import * as React from 'react';
import { Select, MenuItem, FormControl, InputLabel, styled, ListItemText, Checkbox } from "@mui/material";

const CenteredInputLabel = styled(InputLabel)({
  textAlign: 'center',
  transform: 'translate(14px, 14px) scale(1)',
  fontFamily: 'ActualR',
  fontWeight: 400,
  fontSize: '14px',
  color: '#666666',
  '&.Mui-focused': {
    transform: 'translate(14px, -8px) scale(0.85)',
    color: "#666666",
  },
  '&.MuiInputLabel-shrink': {
    transform: 'translate(14px, -8px) scale(0.85)',
    color: "#333333",
  },
  '&[data-shrink="false"] + .MuiInputBase-formControl .MuiSelect-select': {
    paddingTop: '16px',
  },
});

const CustomSelect = styled(Select)(() => ({
  width: 285,
  height: "48px",
  display: "flex",
  alignItems: "center",
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#aaa",
    },
    "&:hover fieldset": {
      borderColor: "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#aaa",
    },
  },
}));

const DropdownMulti = (props: any) => {
  const [personName, setPersonName] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (options.length <= 0) {
      setOptions(props?.data);
    }// eslint-disable-next-line
  }, [props?.data]);

  React.useEffect(() => {
    if (props?.selectedValues && props?.selectedValues.length > 0) {
      setPersonName(props?.selectedValues);
    }
  }, [props?.selectedValues]);

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    } else {
      let {
        target: { value },
      } = event;
      if (value[value.length - 1] === 'All') {
        value = value.length === props.data.length + 1 ? [] : props.data;
        setPersonName(typeof value === 'string' ? value.split(',') : value);
      } else {
        setPersonName(
          typeof value === 'string' ? value.split(',') : value,
        );
      }
      props.handleSelection(value);
    }
  };

  const filterOptions = (value: any, options: any, initialOptions: any) => {
    let filteredArray = options.filter((item: any) => item.toLowerCase().includes(value.toLowerCase()));
    if (filteredArray.length > 0) {
      setOptions(filteredArray);
    } else {
      setOptions(initialOptions);
    }
  };

  const handleClose = () => {
    setOptions(props?.data);
  };


  return (
    <FormControl variant="outlined">
      <CenteredInputLabel
        id="demo-simple-select-helper-label"
        htmlFor="select-multiple"
      >
        {props?.label}
      </CenteredInputLabel>
      <CustomSelect
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        multiple
        disableUnderline
        value={personName}
        displayEmpty
        size="small"
        onClose={handleClose}
        onChange={handleChange}
        IconComponent={() => (
          <i 
            className="icon-h-down-arrow icon_downarrow" 
            style={{ cursor: 'pointer', position:'absolute', right:'0', pointerEvents:'none' }}
          />
        )}
        renderValue={(selected: any) =>
          selected.length > 0 && selected.length === options?.length
            ? "All"
            : selected.length > 1
            ? "Multiple Values"
            : selected.join(", ")
        }
        {...props}
      >
        <MenuItem
          value={""}
          onClickCapture={stopImmediatePropagation}
          onKeyDown={(e) => e.stopPropagation()}
          style={{ background: 'transparent', margin:'8px 0px 0px 8px' }}
        >
          <input
            className="search_box"
            type="text"
            placeholder="Search"
            onChange={(e: any) => {
              e.stopPropagation();
              filterOptions(e.target.value, props?.data, props?.partner);
            }}
          />
        </MenuItem>
        <MenuItem key="index" value="All" style={{
                fontFamily: 'Actual',
                fontWeight: 400,
                fontSize:'14px',
          }} >
          <Checkbox
            checked={
              personName.indexOf("All") > -1 ||
              personName.length === props.data.length
            }
          />
          <ListItemText primary="All"/>
        </MenuItem>
        {options?.map((name: any, index: number) => (
          <MenuItem key={index} value={name} title={name} style={{
            fontFamily: 'Actual',
            fontWeight: 400,
            fontSize:'14px',
          }}>
            <Checkbox
              checked={
                personName.indexOf(name) > -1 || personName.indexOf("All") > -1
              }
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};

export default DropdownMulti;
