import React from 'react';
import { GoogleLogin } from '@leecheuk/react-google-login';
import {CDN_URL} from '../../config';
import { GOOGLE_ID } from '../../store/CONSTANTS';
import './Login.style.scss';
import {establishCommunicationToSSO} from '../../utils/helper'


const Login = () =>{
const onSuccess = (res: any) => {
  establishCommunicationToSSO({
    "event" : "login",
    "token" : res?.tokenObj?.id_token, 
    "avatar" : res?.profileObj?.imageUrl
})
// console.log('login')
} 
  const onFailure = (res: any) => {
    // LAYOUT.showToast('ERROR' ,`Failed to login.`)
  };

    return(
        <>
         <div className="outer-cont">
        <div className = "d-flex justify-content-center login-container">
        <img src={`${CDN_URL}partner_assets/electrum/electrum-logo.svg`} alt="logo" />
          <div className="cont-log">
          <h2>Welcome to Hubble!</h2>
         <GoogleLogin
        clientId= {GOOGLE_ID}
        buttonText="Login With Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '100px', width: '200px'}}
        isSignedIn={false}
         />
         <p className="copy">Copyright © {new Date().getFullYear()}. All rights reserved.</p>
         </div>
         </div>
         </div>
        </>
    )
}

export default Login;

