import React, { memo } from 'react';
import { Button } from '@mui/material';
import './Button.style.scss';

// const buttonStyle = {
//   textTransform: 'capitalize',
//   minWidth: '100px'
// };

/**
 *
 * @param {handleClick, label, variant, buttonColor, component, startIcon, EndIcon,id} props 
 * variant as text/contained/outlined
 * buttonColor as secondary/primary/error/info/warning/Any custom color
 * component as span/p/div
 * startIcon , EndIcon as Icon at start/End
 * size as small/medium/large
 * @returns 
*/

const ButtonCustom = (props: any) => (
  <Button
    id={props.id}
    className={`btn btn-default ${props.styleClass} text-capitalize`}
    variant={props.variant}
    color={props.buttonColor}
    component="span"
    onClick={props.handleClick}
    size={props.size ? props.size : 'medium'}
    startIcon={<i className={props.startIcon}></i>}
    endIcon={<i className={props.endIcon}></i>}
    disabled={props.disabled} >
    {props.label}
  </Button>
);

export default memo(ButtonCustom);

