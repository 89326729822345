import {  createSlice } from "@reduxjs/toolkit";

export const ConfigurationInitialState = () => ({
  userPermissions: [],
  userModules: [],
  activeModule : 'projects',
  activeModuleTitle: ""
});

export const configurationSlice = createSlice({
  name: "config",
  initialState: ConfigurationInitialState(),
  reducers: {
    setUserPermission: (state: any, action: any) => {
      state.userPermissions = action?.payload;
    },
    setUserModule: (state: any, action: any) => {
        state.userModules = action?.payload;
    },
    resetUserRole: (state: any) => {
        state.userPermissions = [];
        state.userModules = []
    },
    setActiveModule: (state: any, action: any) => {
      state.activeModule = action?.payload
    },
    setActiveModuleTitle: (state:any ,action: any) => {
      state.activeModuleTitle = action?.payload
    }
  },
});

export const { setUserPermission, setUserModule, resetUserRole, setActiveModule , setActiveModuleTitle} = configurationSlice.actions;

export const getUserConfig = (permissions:any, modules: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch(setUserPermission(permissions));
    return dispatch(setUserModule(modules));
  };
};

export default configurationSlice.reducer;