export const CDN_URL = "https://assets.solar.com/";
export const FILE_UPLOAD_AWS_API_KEY = "QUtJQTNSTVhFU1JBRTdVWlNaVzVYZDkzZmttK1JEM0tQYXU2cHZzSXltYU9OamRSN0xmOVJHMURHNm1k";

export const LANG = {
    SYSTEM_ERROR : "System error. Please try again later!",
    CTA_YES : "Yes",
    CTA_NO : "No",
    CTA_APPROVE : "APPROVE",
    CTA_REJECT : "REJECT",
    CTA_CANCEL : "Cancel",
    CTA_SAVE : "Save",
    LINKED_PROJECTS: 'No linked projects are present at the moment.',
    NO_DATA_AVAILABLE: "The information you're seeking is presently not available.",
    API_ERROR : "Update Failed. Unfortunately, encountered an issue while trying to update the data. Please refresh the page and try again."

}
export const HTTP_STATUS = Object.freeze({
    PENDING: 'PENDING',
    FULFILLED: 'SUCCESS',
    REJECTED: 'REJECTED'
})