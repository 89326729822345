import './App.css';
import './support/utils/dropConsole';
import './assets/style/Hubble.style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/js/src/collapse.js";
import {BrowserRouter} from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/account/Login';
import Layout from './HOC/Layout';
import MainLayout from './HOC/mainLayout';
import { useSelector } from 'react-redux';
import RouterConfig from './navigation/RouterConfig';
import SetupInterceptors from './utils/interceptors/AxiosInterceptor';
import { PreLoader } from './layout/UI/PreLoader';
import './assets/scss/control-center.scss';

function App() {
  const store = useSelector((store:any) =>store.auth)
  // const history = createBrowserHistory();
  // const [loader, setLoader] = useState(false);
  // const setLoader = () => {setLoader(!loader)}
  if(window.self !== window.top) {
    document.body.style.display = 'none';
  }
  SetupInterceptors();
   
  return (
    <>
        <BrowserRouter>
        <PreLoader loader={store.loader.length > 0}/>
        <Layout>
          <MainLayout>
          <div className="App">
          {
            !store?.isLoggedIn ?
            <Login />
            : <RouterConfig />

          }
        </div>
          </MainLayout>
        </Layout>
        </BrowserRouter>
    </>
  );
}

export default App;
