import React from 'react';

export const PreLoader = (props: any) => {

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${props.loader ? "" : "show"}`}>
      <div className="loader1">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
    </div>
  );
};
