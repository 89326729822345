import axios from "axios";
import store from '../../store';
import {setLoaderStore} from "../../store/auth/authSlice";

const SetupInterceptors = () => {
    axios.interceptors.request.use(
        // console.log(`${req.method} ${req.url} ${req}`);
        // Important: request interceptors **must** return the request.

        (req) => requestHandler(req),
        (error) => errorHandler(error)
    );

    axios.interceptors.response.use(function (response) {
        return responseReceived(response);
    }, function (error) {
        var status = error?.response?.status || '';
        if (status === 401) {
            localStorage.clear();
            window.location = '/';
        }
       return errorHandler(error);
    });


    const requestHandler = request => {
        requestSent(request.url);
        return request;
      };
      
      const errorHandler = error => {
        errorMonitor(error);
        return Promise.reject(error);
      };

      const requestSent = (url) =>{
        const requestQueue = store.getState().auth.loader;
        if (!url.includes('ignoreLoader=true') && requestQueue.indexOf(url) === -1) {
            let req = [...requestQueue];
            req.push(url);
            store.dispatch(setLoaderStore(req));
            }    
        }
    
      const responseReceived =(response)  => {
        const requestQueue = store.getState().auth.loader;
        let urlStriped = response.config.url;
        if (requestQueue.indexOf(urlStriped) > -1) {
          let req = [...requestQueue];  
          req.splice(requestQueue.indexOf(urlStriped), 1);
          store.dispatch(setLoaderStore(req));
        }
        return response;
      }

      const errorMonitor = (error) =>{
        const requestQueue = store.getState().auth.loader;
        let urlStriped = error.config.url;
        if (requestQueue.indexOf(urlStriped) > -1) {
          let req = [...requestQueue];  
          req.splice(requestQueue.indexOf(urlStriped), 1);
          store.dispatch(setLoaderStore(req));
        }
      }

    //   const stripIgnoreLoaderQuery = (url) => {
    //     return url.replace('?ignoreLoader=true', '').replace('&ignoreLoader=true', '');
    //   }
}

export default SetupInterceptors;