import axios from "axios";

export const handleError = (error: any) => {
  // handleToken(error);
  // let message = isEmpty(error?.request?.response?.message)
  //   ? isEmpty(error?.response?.data)
  //     ? "Something went wrong. Please try again."
  //     : error?.response?.data?.message
  //   : error?.request?.response?.message;

  // /*  return window.open(
  //     "https://login.microsoftonline.com/common/oauth2/v2.0/logout",
  //     "_self"
  //   ); */
  // if (error?.request?.status !== 404) {
  //   // toastr.error("", message, getNotificationConfig("error"));
  // }
};


export const handleFormat = (data: any, key: string | number, type: string) => {
  if (type === "list") {
    let request: any[] = [];
    data.forEach((item: any) => {
      let object = { ...item };
      object[key] = object[key]?.split("_")[0];
      request.push(object);
    });
    return request;
  } else {
    return data.split("_")[0];
  }
};

export const handleFailed = (error: any) => {
  if (error?.request?.status === 401) {
    return true;
  } else {
    handleError(error);
  }
};

const axiosService =(
  method: string,
  url: string,
  needAuth: boolean,
  headers?: any,
  urlEncoded?: boolean,
  data?: any,
  callback?: any)=> {
  var _HEADERS: {[key: string]: any} = {
    'Content-type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: "no-cache",
    Expires: "0",
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest'
  };
  if (needAuth) {
    _HEADERS = ({
      ..._HEADERS,
      'Authorization': 'Bearer ' + JSON.parse(localStorage.token)
    });
  }
  if(urlEncoded){
    _HEADERS = ({ ..._HEADERS,'Content-type': 'application/x-www-form-urlencoded '})
  }else{
    _HEADERS = ({ ..._HEADERS, 'Content-type': 'application/json'});
  }
  if (headers) {
    Object.keys(headers).forEach((header) => {
      _HEADERS = ({..._HEADERS,
        [header]: headers[header]});
    });
  }
  switch (method) {
    case 'GET' :
      return axios.get<any>(url,
        {headers: _HEADERS}
      );
    case 'POST' :
      return axios.post<any>(url, data, {headers: _HEADERS})
    case 'PUT' :
      return axios.put<any>(url, data, {headers: _HEADERS});
    case 'PATCH' :
    return axios.patch<any>(url, data, {headers: _HEADERS});
    case 'DELETE' :
      return axios.delete<any>(url, {headers: _HEADERS});  
  }

}

export default axiosService;
