import {DASHBOARD} from '../navigation/CONSTANTS';

export const BREADCRUMB_NAME = {
    "projects":{
       'project-list' : { name :'Project List', redirect : DASHBOARD.PROJECT_LIST },
       'project-files-list' : { name: 'Project Files', redirect : ''},
        'equipment': { name: 'Equipment', redirect : ''},
        'detail': { name: 'Detail', redirect : ''},
        'tools': { name: 'Tools', redirect : ''},
        'files': { name: 'Project Files', redirect : ''},
        'project-list-new' : { name :'Project List (New)', redirect : DASHBOARD.PROJECT_LIST_NEW }
    },
}

export var ACTIVE_BREADCRUMB_NAME = '';
