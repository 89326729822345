import React, {useEffect, useState } from 'react';
import ButtonCustom from '../../../../../layout/UI/Button';
import DropdownFilter from '../../shared/dropdownMulti';
import {getprojectFileListFilters, setSearch} from '../../../../../store/projectList/projectListSlice'
import {swapKeysAndValues} from '../../../../../utils/helper'
import { useDispatch, useSelector } from 'react-redux';

export default function StaffFilter(props){
    const dispatch = useDispatch();
    const projectFilesData = useSelector((store) => store.projectList)

    const [filters, setFilters] = useState({
        "installer_access":[],
        "status":[],
        "type":[]
})
// const [search, setSearch] = useState({
//             "installer_access":[],
//             "status":[],
//             "type":[]
// })
const [filterMappings, setFilterMappings] = useState({})

    useEffect(() => {
        getprojectFileListFilters().then((response) => {
            if(response.data.success){
                const data = response.data.data;
                var filter = {
                    installer_access : {},
                    status: {},
                    type: {}
                };
                // context.setFilterData(response.data.data);
                // eslint-disable-next-line array-callback-return
                Object.keys(data).map((item) =>{
                  filter[item]["key"] = [];
                  filter[item]["value"] = [];
                  filter[item]["key"] = Object.keys(data[item]);
                  filter[item]["value"] = Object.values(data[item]);
                })
                    var searchData = {
                        "installer_access" : filter.installer_access.key,
                        "status" : filter.status.key,
                        "type" : filter.type.key
                    }
                    var filterData = {
                        "installer_access" : filter.installer_access.value,
                        "status" : filter.status.value,
                        "type" : filter.type.value
                    }

                   dispatch(setSearch(searchData))
                   setFilters(filterData);

                   setFilterMappings({
                    installer_access : swapKeysAndValues(data.installer_access),
                    status: swapKeysAndValues(data.status),
                    type: swapKeysAndValues(data.type)
                   });

            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelection = (val, type, key) => {
      const value = [];
      // eslint-disable-next-line array-callback-return
      val.map((item) => {
          if(item === 'All'){

          }else{
          value.push(filterMappings[key][item]);
          }
      })
      dispatch(setSearch({...projectFilesData.search, [key]: value}))
    //   setSearch({
    //       ...search, [key]: value
    //   })
    }
    
        return (
            <div>
                <div className="d-flex justify-content-start align-item-center filter-section" style={{"padding": "0px 20px"}}>
                <div>
                    <p className="mb-0 mt-2 filter-label">Installer Access</p>
                    <DropdownFilter partner={filters.access} data={filters.installer_access || []}
                    handleSelection = {(e) => handleSelection(e, 'installer_access','installer_access')}></DropdownFilter>
                </div>
                <div>
                    <p className="mb-0 mt-2 filter-label">Status</p>
                    <DropdownFilter partner={filters.status} data={filters.status || []}
                    handleSelection = {(e) => handleSelection(e, 'status','status')}></DropdownFilter>
                </div>
                <div>
                    <p className="mb-0 mt-2 filter-label">File Type</p>
                    <DropdownFilter partner={filters.type} data={filters.type || []}
                    handleSelection = {(e) => handleSelection(e, 'type', 'type')}></DropdownFilter>
                </div>
                <div>
                    {/* <p className="mb-0 mt-2 filter-label">.</p> */}
                    <ButtonCustom label="Search"
                    variant="contained" 
                    buttonColor= "success"
                    handleClick={() => props.searchHandler(projectFilesData.search)}>
                    </ButtonCustom>
                </div>
                </div>
            </div>
        );
}
