import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { MENU_ITEM, CARDS } from '../../../config'
import { CDN_URL } from '../../../config'
// import { DashboardContext } from '../../services/dashboard/dashboardContext'
import { isActionAllowed, isModuleAllowed , capitalize}  from '../../../utils/helper'
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import './Sidebar.style.scss';
import { setNotesSectionHandler, setOverViewChangesHandler } from '../../../store/projectList/projectListSlice';
import { InfoDialog } from '../../UI/dialog/dialog';


function GetMenuIcon({ idx, item, activeRoute, handleClick, activeRouteTitle }) {
  return (
    <Tooltip title={capitalize(item.title)} placement="right" arrow key={idx}>
      <div className={`menu-content ${activeRoute === item.title ? 'nocolor'  : ''}`} onClick={() => handleClick()}>
        {/* <img className="icon" src= {`${CDN_URL}${item.icon}`} alt="sidemenu" ></img> */}
        <i className={`${item.icon} side-icon ${activeRouteTitle === item.title ? 'active' : ''}`} />
      </div>
    </Tooltip>
  );
};

function GetAccordionItem({ item }) {

  return (
    <>
      {
        item.accordion ?
          <Accordion>
            <AccordionSummary
              expandIcon={<i className="icon-h-down-arrow" />}
              id={item.title}
            >
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                item.menu.forEach((menu, idx) => (
                  menu.accordion ?
                    <GetAccordionItem item={menu}  key ={idx}/>
                    :
                    <NavLink key={idx} to={menu.path} className="nav-link">{menu.title}</NavLink>
                ))

              }
            </AccordionDetails>
          </Accordion>
          : null
      }
    </>
  )
}


const SideMenu = (props: any) => {
  // const dispatch =useDispatch();
  const ModuleData = useSelector((state: any) =>  state.config.activeModule);
  const projectDetail = useSelector((store:any) => store.projectList);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmPopup, setConfirmPopup] = useState({
    isVisible: false,
    title: "Alert",
    buttonText: 'Confirm',
    closeButtonText:'Cancel',
    data1: "",
    data2:'',
    update_function: null,
  });
  // console.log('sidebar executing...')
  // const context = useContext(DashboardContext);
  const { pathname } = useLocation();
  // const [render, setRender] = useState(true);

  useEffect(() => {
    // let path = pathname.split('/')[2];
    // dispatch(setActiveModule(path))
    // dispatch(setActiveModule(string(path)));
    // context.setActiveRouteTitle(path);
    // setRender(!render);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  const changeScreen = (params = '/', title: any, available = false, permission: any) => {
    //Following commented out code is a feature that asked to revert
    // if(available){
    //   // MENU_ITEM[title].map((item, index) => {  
    //     if( permission === '' || isActionAllowed(permission)){
    //   for ( var item of MENU_ITEM[title]) {
    //     if(item.permission === ''){
    //       history.push(params);
    //     }else
    //       if(isActionAllowed(item.permission)){
    //         history.push(item.path);
    //         context.setActiveRoute(title);
    //         break;
    //       } 
    //   }
    //   // history.push(params);
    //   context.setActiveRoute(title);
    // }else{
    //   showToast('ERROR' ,`Permission Denied! You are not allowed to access ${title}`);
    // }
    // }else {
    // showToast('INFO' ,'Coming Soon!')
    // }
    // context.setActiveRoute(title);
    props.setToggle(true);
    
  }


  const activeTitle = (menu: any, title: any) => {
    props.setToggle(false); 
    // context.setActiveRouteTitle(title);
  }

  const handleClick = (item:any, data:any) => {
    activeTitle(item, data);
    if(!projectDetail.overViewChangesHandler && !projectDetail.notesSectionHandler){
      navigate(item.path);
    }else{
      setConfirmPopup({
        ...confirmPopup,
        isVisible:true,
        data1: 'You have made some changes in Overview section',
        data2: 'Are you sure you want to move without saving the changes ?',
        update_function: () => {
          navigate(item.path);
          const detailsFlag : any = false;
          dispatch(setNotesSectionHandler(detailsFlag));
          dispatch(setOverViewChangesHandler(detailsFlag));
        }
      })
    }
  }

  return (
    <>
      <div id="sidebar-component">
        <div className="fixed-sidebar-icon">
          <div className="menu-content" onClick={() => props.setToggle(!props.toggle)}>
            {/* <i className={`icon-four_square side-icon `} />  */}
            <img className="icon" src={`${CDN_URL}partner_assets/control-center/dummy/four_square.svg`} alt="sidemenu"
            ></img>
            <hr className="icon mb-0" />
          </div>
          {
            CARDS.map((item, index) => (
              item.type !== 'link' && (item.available && isModuleAllowed(item.roles)) &&
              <GetMenuIcon key={index} idx={index} item={item} activeRoute={ModuleData} activeRouteTitle={'projects'}
                handleClick={() => changeScreen(item.redirect, item.title, item.available, item.permissions)}
              />
            ))
          }
        </div>
        <div className={`sidenav ${props.toggle ? 'close' : 'open'}`}>
          {/* <div style={{textAlign: 'right', marginTop: '40px'}}>
          <img src={`${CDN_URL}partner_assets/control-center/sidebar.svg`} alt="sidebar"
        onClick={() => props.setToggle(!props.toggle)}></img>
          </div> */}
          <Nav defaultActiveKey="/home" className="nav-s" style={{ 'marginTop': '20px' }}>
            <label style={{ 'color': '#333333', 'fontSize': '18px', 'fontFamily': 'ActualB', 'textTransform': 'capitalize' }}>projects</label>
            <hr className="divider" />
            {true ?
              MENU_ITEM[ModuleData].map((item: any, index: number) => (
                item.accordion ?
                  <GetAccordionItem item={item} key={index} />
                  : isActionAllowed(item.permission) && <NavLink key = {index} to='#' onClick={() => handleClick(item, ModuleData)} className="nav-link">{item.title}</NavLink>
                  // <span className={`nav-link`} key={index} onClick={() => activeTitle(item, context.state.activeRoute)} >{item.title}</span>
                // !item.accordion ?
                //   item.permission === '' ?
                //    <NavLink key = {index} to={item.path} className="nav-link">{item.title}</NavLink>
                //    : 
                //     isActionAllowed(item.permission) && <NavLink key = {index} to={item.path} className="nav-link">{item.title}</NavLink>
                //  : <GetAccordionItem  item = {item}/>   
              ))
              : null
            }
          </Nav>
        </div>
      </div>
      <InfoDialog
        maxWidth="sm"
        show={confirmPopup.isVisible}
        version="second"
        handleClose={() => {
          setConfirmPopup({
            ...confirmPopup,
            isVisible: !confirmPopup.isVisible,
          });
        }}
        closeBtnStyle={`button-var5_close`}
        closeButtonText={confirmPopup.closeButtonText}
        buttonText={confirmPopup.buttonText}
        saveChanges={() => {
          confirmPopup.update_function();
          setConfirmPopup({
            ...confirmPopup,
            isVisible: !confirmPopup.isVisible,
          })
        }}
        title={confirmPopup.title}
      ><div className="p-2">
        <p style={{ color: "#121212", fontFamily: "ActualM" }}>
          {confirmPopup.data1}
        </p>
        {confirmPopup.data2.length > 0 && (
        <li>{confirmPopup.data2}</li>
        )}
        </div>
      </InfoDialog>
    </>
  )
}


export default SideMenu;

