import {lazy, Suspense} from 'react';
import './HomeDashboard.style.scss';
// import {getChannelPartner} from '../../../store/actions'
// import {DashboardContext} from '../../../services/dashboard/dashboardContext';
import {MODULE} from '../../../config';
import { useSelector } from 'react-redux';

const Cards = lazy(() => import('./cards'));
const Greeting = lazy(() => import('./greeting'));



const HomeDashboard = () =>{
  const store = useSelector((store: any) => store.auth)
    // const context = useContext(DashboardContext)
    // const storeData = useSelector((state) => state)
    // let dispatch = useDispatch();

    // useEffect(() => {
    //   if(storeData.data.partners.length === 0)
    //   dispatch(getChannelPartner());
    // }, [dispatch, storeData.data.partners])

    // useEffect(() => {
    //   context.prepareRoleList(storeData.user.payload.data.user_roles);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []) 
    return(
        <>
        <div className = "home-dashboard">
        <div className="dashboard-landing">
        <Suspense fallback={<h1>Loader....</h1>}> 
        <Greeting name = {store?.data?.name}
        ></Greeting>
        </Suspense>
        {
          true &&
        <Suspense fallback={<h1>Loader....</h1>}>
        <Cards module = {MODULE}
        ></Cards>
        </Suspense>
        }

        </div>
        </div> 
     </>
    )
}

export default HomeDashboard;