import React, { Suspense, useState } from "react";
// import { useAuth , canActivateRoute} from "./ProvideAuth";
import { PreLoader } from "../layout/UI/PreLoader";
import SideMenu from "../layout/components/sidebar";
import Breadcrumb from "../layout/components/Breadcrumb";
// import Breadcrumb from '../../layout/components/Breadcrumb';

export const PrivateRouteWithSidebar = ({
  component: Component,
  layout: Layout,
  redirect,
  permissions,
  showBreadcrumb,
  ...rest
}: any) => {
  const [toggle, setToggle] = useState(false);
  let auth = true;
  function canActivateRoute(permissions: any) {
    return true;
  }

  return auth ? (
    canActivateRoute(permissions) ? (
      <>
        <div>
          <div className="mainLayout-component">
            <Layout>
              <SideMenu toggle={toggle} setToggle={setToggle} />
              <div
                id="main"
                className={toggle ? "open-mleft" : "close-mleft"}
                onClick={() => setToggle(false)}
              >
                <div>
                  {!showBreadcrumb && (
                    <Breadcrumb toggle={toggle} setToggle={setToggle} />
                  )}
                </div>
                <Suspense fallback={<PreLoader loader={true}></PreLoader>}>
                  <Component />
                </Suspense>
              </div>
            </Layout>
          </div>
        </div>
      </>
    ) : redirect.status ? (
      redirect(redirect.component)
    ) : (
      redirect("/dashboard")
    )
  ) : (
    redirect("/")
  );
};
