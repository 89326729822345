import * as React from 'react';
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { styled } from '@material-ui/core/styles';

/**
 * 
 * @param {SwitchType, checked,handleChange, label, variant, disabled} props 
 * label = { inputProps: { 'aria-label': 'Switch toggle' , 'title' = 'Toggle' } };
 * @returns 
 */

// Custome theme color palette to add customised colors.
const theme = createTheme({
    palette: {
        // electrum: {
        //     main: '#5029DD',
        // },
    },
});
// const MyButton = styled(Switch)({
//   background:'none',
//   hover:'none'
//   });

export default function ToggleSwitch({
    SwitchType = 'controlled',
    checked = true,
    handleChange,
    label = {},
    variant = '',
    disabled = false
}) {

    const controlledSwitch = () => {
        return (
            <ThemeProvider theme={theme}>
                <Switch checked={checked}
                    onChange={handleChange}
                    {...label}
                    disabled={disabled}
                    className = "hubble-checkout"
                />
            </ThemeProvider>
        )
    }


    const Toggle = () => {
        switch (SwitchType) {
            case 'controlled': return controlledSwitch();
            default: return controlledSwitch();
        }
    }

    return (
        <>
            <Toggle />
        </>
    );
}