/**
 * TODO
 * Declare all router constants here
 */

export const ROOT = "/";
export const LOGIN = ROOT;
export const DASHBOARD = {
    INIT : "/",
    PROJECT_LIST : '/dashboard/projects/project-list',
    PROJECT_LIST_NEW : '/dashboard/projects/project-list-new',
    PROJECT_FILES_LIST:'/dashboard/projects/project-list/files/:id',
    PROJECT_HOME : '/dashboard/projects/project-detail/:id',
    PROJECT_CONSUMPTION : '/dashboard/projects/project-detail/:type/:id',
    PROJECT_BIDS_DETAIL : '/dashboard/projects/project-detail/:projectId/bids/:bidId',
    PROJECT_CHECKOUT : '/dashboard/projects/project-detail/:id/checkout/:id',
    PROJECT_MODULE_LIST : '/dashboard/projects/project-list',
    PROJECT_MODULE_HOME : '/dashboard/projects/:type/:id',
}

