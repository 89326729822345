import { Action, createSlice } from "@reduxjs/toolkit";
import axiosService, { handleFailed } from "../../support/http/AxiosService";
import { ENGINE_URL } from "../CONSTANTS";
import { getUserConfig } from "../config/configSlice";
import axios from "axios";
import { ThunkAction } from 'redux-thunk';

export const authenticationInitialState = () => ({
  isLoggedIn: false,
  loginErrorMessage: "",
  data: {},
  loader: []
});

export const authenticationSlice = createSlice({
  name: "auth",
  initialState: authenticationInitialState(),
  reducers: {
    loginAction: (state: any, action: any) => {
      state.isLoggedIn = true;
      state.loginErrorMessage = "";
      state.data = action?.payload?.record
    },
    loginErrorAction: (state: any, action: any) => {
      state.isLoggedIn = false;
      state.loginErrorMessage = action.payload;
    },
    resetErrorMessage: (state: any) => {
      state.loginErrorMessage = "";
    },
    logoutAction: (state:any) => {
      state.isLoggedIn = false;
      state.loginErrorMessage = "";
    },
    setLoaderStore: (state: any, action: any) => {
      state.loader = action.payload;
    }
  },
});

export const { loginAction, loginErrorAction, resetErrorMessage, logoutAction, setLoaderStore } = authenticationSlice.actions;

export const performLogin = (values: any): ThunkAction<void, any, unknown, Action> => 
   async (dispatch: any, getState: any) =>{
    if(getState().config.userPermissions.length === 0 && getState().config.userModules.length === 0){
      const getPermissionsDetail = axiosService('GET', ENGINE_URL + `acl/get-user-permission-details`, true);
      const getRolesDetails = axiosService('GET' , ENGINE_URL + `acl/get-user-details`, true);
  
      axios.all([getPermissionsDetail, getRolesDetails])
      .then(axios.spread((module: any, permission: any) => {
          const list = [];
          module?.data?.data.forEach((item: any) => {
              list.push(item.module_slug)
          })
          dispatch(loginAction(values));
          dispatch(getUserConfig(permission.data?.data?.permissions_list, list));
      }))
      .catch(error => {
        handleFailed(error);
        dispatch(loginErrorAction(error.response?.data?.message));
      });
    }
  // }
  // else{
  //   dispatch(loginAction(values));
  // }
};

export const performLogout = () => {
  return async (dispatch: any) => {
  return dispatch(logoutAction());
  }
};

export const isLoggedInSelector = (state: any) => state.auth.isLoggedIn;
export const getLoggedInErrorMsgSelector = (state: any) => state.auth.loginErrorMessage;

export default authenticationSlice.reducer;