/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 25/07/2024 - 13:39:06
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/07/2024
    * - Author          : 
    * - Modification    : 
**/
import React from 'react';
import NavBar from '../layout/components/navbar'
import Footer from '../layout/components/footer'
import { useSelector } from 'react-redux';

const MainLayout = (props: any) =>{
    const store = useSelector((store:any) =>store.auth)
    return(
        <>
        { store?.isLoggedIn  && <NavBar /> }
        <div className={store?.isLoggedIn ? "main-head" : ""}>
            {props.children}
        </div>
        { store?.isLoggedIn &&<Footer /> }
        </>
    )
}

export default MainLayout;