import { Action, createSlice } from "@reduxjs/toolkit";
import { HUBBLE_ENGINE_URL } from "../CONSTANTS";
import { ThunkAction } from 'redux-thunk';
import axiosService from "../../support/http/AxiosService";
import { showToast } from "../../layout";


export const quotesInitialState = () => ({
    quotesInfo : {},
    quotesData : [
      { id: '1', lineItem: 'Base Price - NEMA Outlet', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '2', lineItem: 'Region Adder', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '3', lineItem: '240V line Run', desc: '10-20 ft', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '4', lineItem: 'Obstructions', desc: 'Some small', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '5', lineItem: 'Trenching', desc: '0 ft', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '6', lineItem: 'Main Panel Upgrade', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '7', lineItem: 'Load Shed Device', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '8', lineItem: 'GFCI Breaker', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '9', lineItem: 'Misc. Costs', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: true },
      { id: '10', lineItem: 'Installation Sub-total', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: false },
      { id: '11', lineItem: 'Electrum Fee', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: false },
      { id: '12', lineItem: 'Grand Total', desc: '-', quoteLow: 0, quoteHigh: 0, actual: 0, input: false },
    ]
});

export const quotesSlice = createSlice({
    name: "quotes",
    initialState: quotesInitialState(),
    reducers: {
        setQuotesInfo(state:any, action:any){
            state.quotesInfo = action.payload
        },
        sestQuotesData(state:any, action:any){
          state.quotesData = action.payload
        }
    }
});

export const {setQuotesInfo,sestQuotesData} = quotesSlice.actions;


//getQuoteInfo

export const getQuoteInfo = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST',
    HUBBLE_ENGINE_URL + `getQuoteInfo`,true,null,false,data
  ).then((response) => {
    if (response.data.success) {
      dispatch(setQuotesInfo(response.data.data));
    }
  }).catch((err)=>showToast('ERROR',err.response.data.message));
};

//getElectrumFee

export const getElectrumFee = async (data: any,loader:Boolean = true) => {
  return axiosService('POST',
  HUBBLE_ENGINE_URL + `getElectrumFee${loader ? '?ignoreLoader=true' : ''}`,true, null, false,
  data
);
};

//updateQuoute

export const updateQuoteInfo = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST',
    HUBBLE_ENGINE_URL + `updateQuoute`,true,null,false,data
  ).then((response) => {
    if (response.data.success) {
      const payload: any = {
        "project_id": data.projectId
      }
      dispatch(getQuoteInfo(payload));
      showToast('SUCCESS','Final Quote sent Successfully');
    }
  }).catch((err)=>showToast('ERROR',err.response.data.message));
};

export default quotesSlice.reducer;