import { createSlice } from "@reduxjs/toolkit";
import { ENGINE_URL, HUBBLE_ENGINE_URL } from "../CONSTANTS";
import axiosService from "../../support/http/AxiosService";

import axios from "axios";


export const consumptionInitialState = () => ({
  data: {},
  consumptionUserDataLength: undefined,
  consumptionData: {},
  consumptionGenabilityData: [],
  genabilityCount: {
    totalCost: undefined,
    kwhSum: undefined,
  },
  planelBoxAmpValue: {},
  consumptionDataFlag: false,
  lastUpdated:null,
  greenButtonData:{},
  accountPropertiesLoader:false,
  rateSchedulesLoader:false,
  intervalLoader:false,
});

export const consumptionDetailSlice = createSlice({
  name: "consumption",
  initialState: consumptionInitialState(),
  reducers: {
    setConsumptionUserDataLength(state: any, action: any) {
      state.consumptionUserDataLength = action.payload
    },
    setGenabilityCount(state: any, action: any) {
      state.genabilityCount = {
        totalCost: action.payload.totalCost,
        kwhSum: action.payload.kwhSum,
      }
    },
    setPanelBoxAmpValue(state: any, action: any) {
      state.planelBoxAmpValue = action.payload
    },
    setConsumptionDataFlag(state:any, action:any){
    state.consumptionDataFlag = action.payload
    },
    setLastUpdated(state:any,action:any){
      state.lastUpdated=action.payload
    },
    setConsumptionData(state:any,action:any){
      state.consumptionData = action.payload
    },
    setGreenbuttonData(state:any, action:any){
      state.greenButtonData = action.payload
    },
    setAccountPropertiesLoader(state:any, action:any){
      state.accountPropertiesLoader = action.payload
    },
    setRateSchedulesLoader(state:any, action:any){
      state.rateSchedulesLoader = action.payload
    },
    setIntervalLoader(state:any, action:any){
      state.intervalLoader = action.payload
    }
  },
});

// eslint-disable-next-line no-empty-pattern
export const {
  setConsumptionUserDataLength,
  setGenabilityCount,
  setPanelBoxAmpValue,
  setConsumptionDataFlag,
  setLastUpdated,
  setConsumptionData,
  setGreenbuttonData,
  setAccountPropertiesLoader,
  setRateSchedulesLoader, setIntervalLoader } = consumptionDetailSlice.actions;

export const gethubbleInfo = async (id: any) => {
  return axiosService(
    "GET",
    HUBBLE_ENGINE_URL + `consumption-page-info/${id}?ignoreLoader=true`,
    true
  );
};

export const getLses = async (id: any) => {
  return axiosService('GET', HUBBLE_ENGINE_URL + `lses/${id}?ignoreLoader=true`, true);
};

export const getTraiffs = async (id: any, lseId: any) => {
  return axiosService('GET', HUBBLE_ENGINE_URL + `tariffs/${id}/${lseId}?ignoreLoader=true`, true);
};

export const getTerritories = async (id: any, lseId: any) => {
  return axiosService('GET', HUBBLE_ENGINE_URL + `territories/${id}/${lseId}?ignoreLoader=true`, true);
};

export const setTariff = async (id: any, data: any) => {
  return axiosService(
    "POST",
    HUBBLE_ENGINE_URL + `set-tariff/${id}?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
};

export const setTerritory = async (id: any, data: any) => {
  return axiosService(
    "POST",
    HUBBLE_ENGINE_URL + `set-territory/${id}?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
};

export const updateAccountProperties = async (id: any, data: any) => {
  return axiosService(
    "POST",
    HUBBLE_ENGINE_URL + `update-account-properties/${id}`,
    true,
    null,
    false,
    data
  );
};

export const getConsumptionData = async (id: any) => {
  return axiosService(
    "GET",
    HUBBLE_ENGINE_URL + `consumption-data/${id}?ignoreLoader=true`,
    true
  );
};

export const updateConsumptionData = async (id: any, data: any) => {
  return axiosService(
    "POST",
    HUBBLE_ENGINE_URL + `update-consumption-data/${id}?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
};

export const updateKwAdjustment = async (data: any) => {
  return axiosService(
    "POST",
    HUBBLE_ENGINE_URL + `consumption-monthlyKwhAdjustment?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
};

export const getGenabilityAccountData = async (data: any) => {
  return axiosService(
    "POST",
    ENGINE_URL + `gs/account/get?ignoreLoader=true`,
    true,
    null,
    false,
    data
  );
};

export const setPanelBoxAmp = async (data: any) => {
  return axiosService('POST', ENGINE_URL + `hubble/set-panelbox-amp`, true, null, false, data);
};

export const getAllConsumptionData = async (id: any) => {
  const urls = [
    HUBBLE_ENGINE_URL + `consumption-page-info/`,
    HUBBLE_ENGINE_URL + `consumption-data/`,
    HUBBLE_ENGINE_URL + `lses/`,
  ]; // array of API endpoints
  const responses = await axios.all(
    urls.map((url) =>
      axiosService("GET", url + `${id}?ignoreLoader=true`, true)
    )
  ); // fire API calls asynchronously
  return responses; // extract response data
};

export const uploadGreenButtonData = async (id: any, data: any) => {
  return axiosService(
    "POST",
    HUBBLE_ENGINE_URL + `upload-green-button-csv/${id}?ignoreLoader=true`,
    true,
    null,
    true,
    data
  );
}


export default consumptionDetailSlice.reducer;