import React, { PureComponent } from 'react';
import './Footer.style.scss';

export default class index extends PureComponent {
  render() {
    return (
      <div id="footer-component">
    <p> Copyright © {new Date().getFullYear()} <a href="https://www.electrum.co" target="_blank" rel="noreferrer">Electrum.co</a>. All rights reserved.</p>
    </div>
    )
  }
}

