const COLORS = {
    color1 : '#101828',
    color2 : '#5029DD',
    color3 : '#333333',
    color4 : '#333333',
    color5: '#E5E5E5'
}

export const setColor = () =>{
    Object.keys(COLORS).forEach((key, index) => {
        document.documentElement.style.setProperty( `--color-${index+1}`, COLORS[key]);
    })   
}

