import React from "react";
import { useDropzone } from "react-dropzone";
import ButtonCustom from "../Button";
import './dropZone.scss';

const  Dropzone = ({ onDrop, accept}) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } =
    useDropzone({
      accept,
      onDrop
    });

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleSelectClick = (e: any) => {
    // prevents the file picker "double open" bug
    e.stopPropagation()
    open()
  }

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        {/* @ts-expect-error */}
        <input className="input-zone" {...getInputProps()} webkitdirectory="true" multiple type="file" />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">
              Release to drop the files here
            </p>
          ) : (
            <p className="dropzone-content text-center">
              Drag and drop files here
            </p>
          )}
          <p style={{'margin' : '10px 0'}} className="text-center">or</p>
          <ButtonCustom label="Select" variant="contained"
            buttonColor="error" styleClass = "btn button-view"
            handleClick={handleSelectClick} className="btn">
          </ButtonCustom>
          {/* <p style ={{'marginTop' : '5px'}}>Max Size : 20MB</p> */}
          <p className="text-center">
          {files.length > 0 && <p style ={{'marginTop' : '5px'}} className="text-center">{files.length} {files.length > 1 ? 'files' : 'file' } selected</p>}
          </p>

        </div>
      </div>
      {/* <aside> */}
        {/* <ul>{files}</ul> */}
      {/* </aside> */}
  </>
  );
}

export default Dropzone;