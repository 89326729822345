import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BREADCRUMB_NAME } from "../../config/breadcrumbConfig";
import {useSelector} from 'react-redux';

const BreadCrumb = (props) => {
  const store = useSelector(store => store.config)
  // console.log(store);
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  useEffect(() => {
    const path = pathname.split("/");
    path.splice(0, 3);
    if(path[path.length-1] >= 0) {path.pop() ;setData(path)}
    else setData(path);
  }, [pathname]);

  const getBreadcrumbText = (item) => {
    return BREADCRUMB_NAME[store.activeModule]?.[item]
      ? BREADCRUMB_NAME[store.activeModule][item].name
      : "-";
  };

  const getBreadcrumbLink = (item) => {
    return BREADCRUMB_NAME[store.activeModule]?.[item]? BREADCRUMB_NAME[store.activeModule][item].redirect
      : "-";
  };

  const getCustomizedText = (text, item) => {
    switch (text){
      case 'add': return 'Add'; 
      case 'view' : return 'View';
      case 'edit' : return 'Edit';
      default : return '-'
    }
  }

  const getBreadcrumb = (item, index) => {
    if (index < data.length - 1) {
      return (
        <NavLink key={index} to={getBreadcrumbLink(item)} className="breadcrumb-item">
          {item === "project-list" || data[index-1] === 'channel-mapping'
            ? store.activeModuleTitle ? store.activeModuleTitle : item
            : getBreadcrumbText(item)}
        </NavLink>
      );
    } else {
      return (
        <Breadcrumb.Item
          key={index}
          className={`${
            index === data.length - 1
              ? " breadcrumb-item active"
              : "breadcrumb-item"
          }`}
        >
          {item >= 0
            ? getCustomizedText(data[index-1], item)
            : getBreadcrumbText(item)}
        </Breadcrumb.Item>
      );
    }
  };

  return (
    <div className="breadcrumbMain">
      <div className="d-flex justify-content-start align-items-center">
        <div>
          <Breadcrumb>
            {data && data.length > 0 ? (
              <>
                <NavLink to="/" className="breadcrumb-item">
                  Home
                </NavLink>
                {data.map((item, index) => getBreadcrumb(item, index))}
              </>
            ) : null}
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
