import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { InputLabel } from '@mui/material';

const DropdownMulti = (props: any) => {
  const [personName, setPersonName] = React.useState(['All']);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if(props.render){
      setPersonName(['All']);
    }
  }, [props.render])

  React.useEffect(()=>{
    if(options.length <= 0){
      setOptions(props?.data);
    }
  },[props?.data])


  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = (event: any) => {
    if(event.key === 'Enter'){
      event.preventDefault();
    }else{
      var {
        target: { value },
      } = event;
         if(value[value.length-1] === 'All'){
             value = value.length === props.data.length +1 ? [] : props.data
             setPersonName(typeof value === 'string' ? value.split(',') : value,
          )
         }else{
          setPersonName(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
            )  
         }
  
         props.handleSelection(value);
    }
  };

  const filterOptions = (value:any,options:any,initialOptions:any) => {
    let filteredArray = options.filter((item:any)=>item.toLowerCase().includes(value.toLowerCase()));
    if(filteredArray.length>0){
      setOptions(filteredArray);
    }else{
      setOptions(initialOptions);
    }
  }



  return (
    <div>
      <FormControl sx={{ m: 1, width: 224 }} variant='outlined'>
      {props?.label && <InputLabel style={{paddingTop:'14px', fontSize:'16px'}} shrink>{props?.label}</InputLabel>}
        <Select
        sx={{height:50}}
          multiple
          value={personName}
          displayEmpty
          onChange={handleChange}
          IconComponent={()=><i className="icon-keyboard_arrow_down icon_down_array" />}
          renderValue={(selected) => (
              selected.length === options?.length ? 'All' : selected.length >1? 'Multiple Values' : selected.join(', ')) }
        >
          <MenuItem value={""}
          onClickCapture={stopImmediatePropagation}
          onKeyDown={e => e.stopPropagation()}><input className='search_box' type='text' placeholder='Search' onChange={(e:any)=>filterOptions(e.target.value,props?.data,props?.partner)}/></MenuItem>
          <MenuItem key="index" value="All">
              <Checkbox checked={personName.indexOf("All") > -1 || personName.length === props.data.length} />
              <ListItemText primary="All" />
          </MenuItem>  
          {options?.map((name: any, index: number) => (
            <MenuItem key={index} value={name} title={name}>
              <Checkbox checked={personName.indexOf(name) > -1 || personName.indexOf("All") > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownMulti
