import {  createSlice } from "@reduxjs/toolkit";
import { ENGINE_URL } from "../CONSTANTS";
import axiosService from "../../support/http/AxiosService";

type objectInterface = {
  disableSaveBtn : Boolean,
  markAsComplete:number,
  total_target_yearly_production:any,
  roofpanelValues:any,
  systemDesign_imageArray:any,
  disableSave:Boolean,
  roofplane_Details:any,
  system_data:any,
  priorities:any,
  energyProfileFields:any,
  designStatus:any,
  panelGroupStatus:Boolean
}

export const systemDesignInitialState = (): objectInterface => ({
  disableSaveBtn: false,
  markAsComplete: 0,
  total_target_yearly_production: null,
  roofpanelValues: {
    panel_id: null,
    inverter_id: null,
    target_yearly_production: null,
    base_panel_size: null,
    roof_type_id: null,
    stories: 1,
    multiple_materials: 0,
    yearly_production: null,
  },
  systemDesign_imageArray: [],
  disableSave: false,
  roofplane_Details: [],
  system_data: {},
  priorities: [],
  energyProfileFields: {
    avg_monthly_consumption: null,
    monthly_bill_adjustment: null,
    monthly_kwh_adjustment: null,
    target_bill_offset: null,
    avg_monthly_bill: null,
    target_monthly_production: null,
    target_yearly_production: null,
  },
  designStatus:false,
  panelGroupStatus:false
});

export const systemDesignSlice = createSlice({
  name: "systemDesign",
  initialState: systemDesignInitialState(),
  reducers: {
    setMarkAsComplete(state: any, action: any){
      state.markAsComplete = action.payload
    },
    setDisableSaveBtn(state: any, action: any){
      state.disableSaveBtn = action.payload
      //state = {...state, ...action.payload}
    },
    setRoofpanelValues(state: any, action: any){
      state.roofpanelValues = action.payload
    },
    setSystemDesign_imageArray(state: any, action: any){
      state.systemDesign_imageArray = action.payload
    },
    setTotalTargetYearlyProduction(state: any, action: any){
      state.total_target_yearly_production = action.payload
    },
    setRoofPlaneDetails(state: any, action: any){
      state.roofplane_Details = action.payload
    },
    setDesignStatus(state:any, action:any){
      state.designStatus = action.payload
    },
    setPanelGroupStatus(state:any,action:any){
      state.panelGroupStatus = action.payload
    }
  },
});

// eslint-disable-next-line no-empty-pattern
export const {setMarkAsComplete, setDisableSaveBtn, setRoofpanelValues, setSystemDesign_imageArray, 
  setTotalTargetYearlyProduction, setRoofPlaneDetails,setDesignStatus,setPanelGroupStatus} = systemDesignSlice.actions;

export const updateDesignFile = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/uploadDesignFile`,true, null, true, data);
};
export const getDesignOptionValues = async () => {
  return axiosService('GET' , ENGINE_URL + `hubble/getDesignOptionValues`, true);
};
export const getDesign = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/getDesign`, true, null, false, data);
};
export const UpdateDesign = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/updateDesign`, true, null, false, data);
};

export const uploadHourlyProduction = async (data: any) => {
  return axiosService('POST' , 
    ENGINE_URL + `hubble/uploadHourlyProductionFile`,true, null, true,
    data
  );
};

export const upsertPanelGroup = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/upsertPanelGroup`, true, null, false, data);
};

export const removeImage = async (data: any) => {
  return axiosService('POST' , 
    ENGINE_URL + `control-center/delete-project-files`,true, null, false,
    data
  );
};
// export const getSolutionData = async (data: any) => {
//   return axiosService('POST' , ENGINE_URL + `hubble/getSolution`,true, null, false, data);
// };

// export const getSolutionList = async (data: any) => {
//   return axiosService('POST' , ENGINE_URL + `hubble/getSolutionList`,true, null, false, data);
// };

// export const calculateAdjustments = async (data: any) => {
//   return axiosService('POST' , ENGINE_URL + `hubble/calculateAdjustments`, true, null, false, data, {loader:false});
// };

export const CreateDesign_api = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/createDesign`, true, null, false, data);
};
export const Editdesign_api = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `hubble/renameDesign`, true, null, false, data);
};
// export const getAdders = async (data) => {
//   return axiosService('GET' , ENGINE_URL + `hubble/getSolutionAdders/${data?.project_id}/${data?.solution_id}` , true )
// }



export default systemDesignSlice.reducer;