import {
    DASHBOARD
} from '../navigation/CONSTANTS';
import { CONTROL_CENTER } from '../store/CONSTANTS';

export const MENU_ITEM = {
    Dashboard: [],
    'projects': [
        {
            title: 'Project List',
            path: DASHBOARD.PROJECT_LIST,
            permission: ['can-view-project-list'],
            accordion : false,
            menu : []
        }
    ]
}

export const CARDS = [
    {
        color: 'info',
        title: 'projects',
        image: `partner_assets/control-center/Project-list-color.svg`,
        icon: `icon-project-list`,
        redirect: DASHBOARD.PROJECT_LIST,
        available: true,
        type: 'module',
        roles: ['project-list'],
        permissions: ['can-view-project-list']
    },
    {
        color: 'info',
        title: 'Control Center',
        image: `partner_assets/control-center/cc_graph.svg`,
        icon: `icon-project-list`,
        redirect: CONTROL_CENTER,
        available: true,
        type : 'link',
        roles: ['project-list'],
        permissions: []
    }
    // {
    //     color: 'info',
    //     title: 'Sirius',
    //     image: `partner_assets/control-center/Project-list-color.svg`,
    //     icon: `icon-project-list`,
    //     redirect: DASHBOARD.PROJECT_LIST,
    //     available: false,
    //     type : 'link',
    //     roles: ['project-list'],
    //     permissions: ['can-view-project-list']
    // }
]

export var PERMISSIONS: any = [];
export var MODULE: any = [];

export const setPermissions = (permission: any) => {
    const list: string[] = [];
    permission.forEach((item: any) => list.push(item.name))
    PERMISSIONS = list;
}

export const setModulePermissions = (modules:any) => {
    MODULE = modules;
}