import React, { PureComponent , lazy} from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeDashboard from '../pages/dashboard/homeDashboard';
import ProjectList from '../pages/dashboard/projectsModule/ProjectList/index';
import ProjectFilesList from '../pages/dashboard/Projects/component/ProjectFile/FileList';
import Financier from '../pages/dashboard/projectsModule/CommonModule/ProjectEntity/index';
import { PrivateRouteWithSidebar } from './PrivateRoute';
import MainLayout from '../HOC/mainLayout';
import { DASHBOARD } from './CONSTANTS';
const SingleBidDetail = lazy(() => import('../pages/dashboard/projectsModule/SolarBatteryModule/Solutions/Bids/SingleBidDetail'));
const ProjectCheckout = lazy(() => import('../pages/dashboard/Projects/component/Solutions/BidDetails/BidscheckoutFile/bidsCheckout'));


export class RouterConfig extends PureComponent {
  render() {
    return (
      <>
      <Routes>
        <Route path={DASHBOARD.INIT} element={<HomeDashboard/>}>
        </Route>
        <Route path = {DASHBOARD.PROJECT_MODULE_LIST} element={
        <PrivateRouteWithSidebar
          path={DASHBOARD.PROJECT_MODULE_LIST}
          component={ProjectList}
          layout={MainLayout}
          permissions= {['can-view-project-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        } />
        <Route path = {DASHBOARD.PROJECT_FILES_LIST} element={
        <PrivateRouteWithSidebar
          path={DASHBOARD.PROJECT_FILES_LIST}
          component={ProjectFilesList}
          layout={MainLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        } />
        <Route path = {DASHBOARD.PROJECT_MODULE_HOME} element={
        <PrivateRouteWithSidebar
          path={DASHBOARD.PROJECT_MODULE_HOME}
          component={Financier}
          layout={MainLayout}
          permissions= {['hubble-view-project-detail-permission']}
          showBreadcrumb = {true}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        } />
        <Route path = {DASHBOARD.PROJECT_BIDS_DETAIL} element={
        <PrivateRouteWithSidebar
          path={DASHBOARD.PROJECT_BIDS_DETAIL}
          component={SingleBidDetail}
          layout={MainLayout}
          permissions= {['hubble-view-bid-detail']}
          showBreadcrumb = {true}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        } />
        <Route path = {DASHBOARD.PROJECT_CHECKOUT} element={
        <PrivateRouteWithSidebar
          path={DASHBOARD.PROJECT_CHECKOUT}
          component={ProjectCheckout}
          layout={MainLayout}
          permissions= {[]}
          showBreadcrumb = {true}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        } />
        
       </Routes>
      </>
    )
  }
}

export default RouterConfig

